<template>
  <div class="ui-main">
<!--    <div class="ui-phone">
      <text style="width: 120px;line-height: 30px">用户手机号:</text>
      <a-input placeholder="请输入用户手机号" v-model:value="phone"></a-input>
    </div>-->
    <div class="ui-movie__box">
      <div class="ui-movie">
        <div v-if="filmLength == 0">暂无影片</div>
        <div class="ui-movie__swiper" v-else>
          <div class="ui-movie__detail" v-for="(item,index) in filmList" :key="index" @click="selectMovie(item,index)" :class="{ 'is-select': currentMovie === index }">
            <img :src="item.imageUrl" class="ui-movie__detailImg">
            <div class="ui-movie__detailName">
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="ui-movieTime" v-if="filmLength != 0">
        <div class="ui-time__box">
          <div class="ui-time__item" v-for="(item,index) in timeList" :key="index" @click="selectTime(index)" :class="{'is-select':currentTime == index}">
            {{item.title}}
          </div>
        </div>

      </div>
      <div class="ui-schedule">
        <a-table rowKey="id" :columns="columns" :dataSource="list" :pagination="false" :loading="isLoading" :scroll="{ y: 440 }" style="height: 500px;width: 640px" bordered>
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'action'">
              <a-button @click="nextBtn(record)" danger>选座购票</a-button>
            </template>
            <template v-else-if="column.key === 'showDateTime'">
              <div v-if="transHours(record.showDateTime) < 6 &&( transDate(record.showDateTime, 1) != transDate(Date.now(), 1))">
                次日放映</div>
              <div>{{transDate(record.showDateTime,3)}}</div>
              <div>{{transDate(record.showEndTime,3)}}散场</div>
            </template>
            <template v-else-if="column.key === 'hallName'">
              <div>{{record.hallName}}
                <text v-if="record.hallType === 'N'">普通</text>
                <text v-else-if="record.hallType === 'I'">IMAX</text>
                <text v-else-if="record.hallType === 'Y'">贵宾</text>
                厅</div>
            </template>
            <template v-else-if="column.key === 'settlePrice'">
              <div>
                <text>￥</text>
                {{ record.settlePrice }}
                <text style="font-size: 14px;">起</text>
              </div>
              <div v-if="record.entityCardPrice&&record.entityCardPrice !== 0" style="color: red">
                <text>会员</text>
                ￥{{ record.entityCardPrice }} 起
              </div>
            </template>
            <template v-else-if="column.key === 'language'">
              <div>
                <text>{{record.language}}{{record.dimensional}}</text>
              </div>
            </template>
          </template>
        </a-table >
      </div>
    </div>

    <div class="ui-btns">
      <a-button type="primary" @click="onPreBtnTap">上一步</a-button>
      <!--      <a-button type="primary" @click="nextBtn">下一步</a-button>-->
    </div>
  </div>

</template>

<script>
import { getFilmList, getScheduleDate, getScheduleList,getCinemaDetail} from "@/service/modules/buyTickets";
import movieClass from "./utils/movieClass";
import transDate from "./utils/transDate";
import transHours from "./utils/transHours";
export default {
  props: {
    cinemaData: {
      type: Object,
      default () {
        return {}
      }
    },
    userPhone: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data() {
    return {
      translateY:0,
      currentMovie: 0,
      num :0,
      columns: [{
        title: '放映时间',
        key:'showDateTime',
        dataIndex: 'showDateTime',
        width: 120,
      }, {
        title: '语言版本',
        dataIndex: 'language',
        key:"language",
        // width: 90,
      }, {
        title: '放映厅',
        key: 'hallName',
        dataIndex: 'hallName',
        // width: 120,
      }, {
        title: '售价（元）',
        key: 'settlePrice',
        // width: 150,
        dataIndex: 'settlePrice'
      },{
        title: '操作',
        key: 'action',
        // width: 120
      }],
      list: [],
      filmList: [],//最近影片列表
      filmLength: 0,//影片列表长度
      filmId: "",//选择的影片id
      currentFilm: {},//选中的影片信息
      classifyInfo: "",//影片类型
      standardCode: "",//选中的影片国家标准编码
      timeList: [], // 可选择时间
      currentTime: 0 ,//当前选择时间index
      cinemaDetail:{},//影院信息
      saleMaxTickets:0,//最大购票数
      isLoading:false,
      phone:''

    }
  },
  created() {
    this.getData();
    this.getCinemaDetail();
    this.clearTimeList()
    if (this.userPhone){
      this.phone = this.userPhone
    }

    console.log(this.cinemaData,'cinemaDatacinemaDatacinemaData')

  },
  methods: {
    //获取影院信息
    async getCinemaDetail() {
      let ret = await getCinemaDetail({
        id: this.cinemaData.cinemaId
      });
      if (ret.code === 200) {
        this.cinemaDetail = ret.data;
        this.saleMaxTickets = ret.data.saleMaxTickets;
      }
    },
    //  获取影片列表
    async getData() {
      this.isLoading = true
      let ret = await getFilmList({
        id: this.cinemaData.cinemaId,
        page: 1,
        pageSize: 999,
        storeId: this.cinemaData.storeId,
        cinemaId: this.cinemaData.cinemaId
      });
      if (ret.code == 200){
        this.filmList = ret.data.list;
        this.filmLength = ret.data.list.length;
        if (this.filmLength != 0){
          this.currentFilm = this.filmList[0]//默认第一部电影
          this.filmId = this.filmList[0].id
          this.standardCode = this.filmList[0].standardCode
          this.getScheduleDate()
        }else {
          this.isLoading=false
        }


      }
    },
    //获取影院电影排期信息
    async getScheduleList() {
      let ret = await getScheduleList({
        cinemaId: this.cinemaData.cinemaId,
        page: 1,
        pageSize: 9999,
        // filmId:this.filmId,
        standardCode: this.standardCode,
        date:this.timeList[this.currentTime].time,
        storeId: this.cinemaData.storeId,
      });
      if (ret.code == 200){
        this.list = ret.data.list
        this.isLoading = false
      }
    },
    // 获取影片可售日期
    async getScheduleDate() {
      this.clearTimeList();
      try {
        let ret = await getScheduleDate({
          standardCode:this.standardCode,
          cinemaId: this.cinemaData.cinemaId,
          storeId: this.cinemaData.storeId,
        });
        if (ret.code === 200) {
          this.clearTimeList();
          if (ret.data && ret.data.length && ret.data.indexOf(0) === -1) {
            let todayStart = this.moment().startOf('day');
            ret.data.forEach(item => {
              let today = ['今天', '明天', '后天'];
              let itemStart = this.moment(item * 1000).startOf('day');
              let i = itemStart.diff(todayStart, 'day')
              let format = 'MM-DD'
              // if (this.uiVersion === 2) {
              //   format = 'yyyy-MM-DD';
              // }
              if (itemStart.valueOf() < todayStart.valueOf()) {
                format = '昨日 MM-DD';
                // if (this.uiVersion === 2) {
                //   format = '昨日 yyyy-MM-DD';
                // }
              } else if (i <= 2) {
                format = today[i] + ' ' + format;
              } else {
                let week = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                let day = itemStart.day();
                format = week[day] + ' ' + format;
              }
              this.timeList.push({
                title: itemStart.format(format),
                time: item,
              });
            })

            // this.timeListLength = ret.data.length;
          } else {
            this.timeList.push({
              title: this.moment().format('今天 MM-DD'),
              time: parseInt(this.moment().valueOf() / 1000),
            })
            // this.timeListLength = 1;
          }
          this.getScheduleList();
        }
      } catch (e) {
        this.timeList.push({
          title: this.moment().format('今天 MM-DD'),
          time: parseInt(this.moment().valueOf() / 1000),
        })
        this.getScheduleList();
      }
    },
    onPreBtnTap(){
      this.$emit("changeCurrent",{index:0,cinemaData:this.cinemaData,phone:this.phone})
    },
    nextBtn(data){
      data.timeDetail = this.timeList[this.currentTime]//传递当前选择的时间
      data.saleMaxTickets = this.saleMaxTickets//传递最大购票数当前选择的时间
      data.cinemaData = this.cinemaData
      this.$emit("changeCurrent",{index:2,movieData:data,phone:this.phone})
    },
    //选择影片的信息
    selectMovie(item,index){
      this.list = []
      this.currentMovie = index
      this.filmId = item.id
      this.currentFilm = item
      this.standardCode = item.standardCode
      this.isLoading = true
      this.getScheduleDate()
    },
    clearTimeList() {
      this.timeList = [];
    },
    //选择时间
    selectTime(index){
      this.list = []
      this.currentTime = index
      this.isLoading = true
      this.getScheduleList()

    },
    //影片类型
    movieClass,
    transDate,
    transHours


  },
};
</script>

<style scoped>
.ui-main {
  /*display: flex;*/
  padding-top: 70px;
}
.ui-phone {
  position: absolute;
  right: 40px;
  top: 40px;
  display: flex;
  width: 280px;
}
.ui-movie__box {
    display: flex;
    /*width: 960px;*/
    height: 500px;
    margin-bottom: 30px;
    /*padding: 0 10px;*/
    /*border: 1px solid #f1f1f1;*/
}

.ui-movie {
  width: 260px;
  height: 100%;
  overflow: hidden;
  padding-bottom: 10px;
  background-color: #f9f9f9;
  /*background-color: #f9f9f9;*/
  /*border: 1px solid #f9f9f9;*/
}

.ui-movie__swiper{
  /* 开启弹性盒，让图片横向排列 */
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 500px;
  /*border: 1px solid #e3e3e3;*/
}
.ui-movie__list {
    overflow: scroll;
}

.ui-movie__detailImg{
    width: 60px;
    height: 87px;
    transition: transform 0.3s ease;
}

.ui-movie__detail {
  display: flex;
  justify-content: start;
  cursor: pointer;
}

.ui-movie__detail:hover {
    opacity: 0.8;
}

.ui-movie__detailName {
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: 700;
    color: #000;
}

.ui-movie__detail.is-select {
    background-color: #1890ff;
}

.ui-movie__detail.is-select .ui-movie__detailName {
    color: #fff;
}

.preMovie{
  position: absolute;
  left: 0px;
  top: 70px;
  width: 50px;
  height: 70px;
  background-color: #fff;

}
.nextMovie{
  position: absolute;
  left: 0px;
  top: 150px;
  width: 50px;
  height: 70px;
  background-color: #fff;
}

.ui-schedule{
    width: 600px;
    height: 500px;
    /*border: 1px solid #f1f1f1;*/
}

.movie-top .top{
  /*width: 250px;*/
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.movie-top .center{
  display: flex;
  justify-content: space-between;
}
.movie-top .movie-role{
  width: 500px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}

.movie-title{
  font-size: 28px;
  font-weight: bold;
}
.movie-score{
  padding-left: 20px;
  font-size: 24px;
}

.ui-btns{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 960px;
}

.ui-movieTime {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100px;
    border: 1px solid #f1f1f1;
}

.ui-time__item{
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}

.ui-time__item:hover {
  background-color: #f9f9f9;
}

.ui-time__item.is-select{
    background-color: #1890ff;
    color: #fff;
}


.ui-time__box{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

</style>
