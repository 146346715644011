<template>
  <a-spin :spinning="loading">
    <div class="ui-main">
      <div class="payOrder">
        <div>请在{{transCountDownTime(orderDetail.lockSeatTime || 0)}}秒内完成支付</div>
        <div>超时订单会自动取消，如遇支付问题</div>
      </div>
      <a-table rowKey="id" :columns="columns" :dataSource="list" :pagination="false" style="margin-bottom: 25px">
        <template #bodyCell="{ column, record, index }">
          <template v-if="column.key === 'filmName'">
            <div>{{ record.ticketData.filmName }}</div>
          </template>
          <template v-if="column.key === 'showDateTime'">
            <div>{{ transDateTime(record.showDateTime) }}</div>
          </template>
          <template v-if="column.key === 'cinemaName'">
            <div>{{record.cinemaData.cinemaName}}</div>
          </template>
          <template v-if="column.key === 'preTicketSeatVOS'">
            <div>
              <span>{{record.ticketData.hallName}}</span>
              <span v-for="(item, index) in record.ticketData.preTicketSeatVOS">
                <span>{{item.rowId}}排{{item.columnId}}列</span>
                <span v-if="index != (record.ticketData.preTicketSeatVOS.length - 1)">、</span>
              </span>
            </div>

<!--            <div>{{record.cinemaData.cinemaName}}</div>-->
          </template>
        </template>
      </a-table>
      <a-form ref="form" :model="formState" name="form" @finish="nextBtn" layout="horizontal">
        <a-row justify="center">
          <a-form-item class="ui-form__item" label="用户手机号" name="phone">
            <a-input v-model:value="phone" placeholder="请输入手机号码" :disabled="confirmPhoneFlag"/>
          </a-form-item>
          <a-button type="primary" style="margin-left: 50px" @click="onConfirmPhoneTap" v-if="!confirmPhoneFlag">确认</a-button>
          <a-button type="primary" style="margin-left: 50px" @click="onEditPhoneTap" v-if="confirmPhoneFlag">修改</a-button>
        </a-row>
        <a-row justify="center">
          <a-form-item class="ui-form__item" label="支付方式" name="payType" :rules="[{required: true, message: '必填选项不允许为空'}]">
            <a-radio-group  v-model:value="formState.payType" @change="onChange">
              <a-radio :value="1" :disabled="!confirmPhoneFlag">支付优惠</a-radio>
              <a-radio :value="9">第三方线下</a-radio>
              <a-radio :value="10">现金支付</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <!-- 支付优惠 start -->
<!--        TODO-->
        <div v-show="formState.payType == 1 && confirmPhoneFlag" class="ui-pay__box is-coupon">
          <a-row style="margin-bottom: 10px;">
            <a-col :span="18"></a-col>
            <a-col :span="6" style="text-align: right;">
              <a-button size="small" @click="onClearCouponTap">清空选择</a-button>
            </a-col>
          </a-row>
<!--          <a-form labelAlign="left" :label-col="{span: 8, pull: 6, offset: 8}">-->
          <a-form labelAlign="left" :label-col="{span: 8,  offset: 4}">
            <a-form-item label="线上会员卡" name="username">
              <span style="margin-right: 20px;color:red" v-if="selectDiscountMode.couponType == 6"> -{{ orderDetail.deductionAmount }}</span>
              <a-button type="primary" @click="onGetCardTap('card', 3)">{{ selectDiscountMode.couponType == 6 ? '修改' : '请选择' }}</a-button>
            </a-form-item>

            <a-form-item label="影城会员卡" name="username">
              <span style="margin-right: 20px;color:red" v-if="selectDiscountMode.couponType == 8">-{{ orderDetail.deductionAmount }}</span>
              <a-button class="ui-coupon__btn" type="primary" @click="onGetCardTap('card', 4)">{{ selectDiscountMode.couponType == 8 ? '修改' : '请选择' }}</a-button>
            </a-form-item>

            <a-form-item label="次卡" name="username">
              <span style="margin-right: 20px;color:red" v-if="selectDiscountMode.couponType == 3"> {{ '已选择' }}</span>
              <a-button class="ui-coupon__btn" type="primary" @click="onGetCardTap('card', 1)">{{ selectDiscountMode.couponType == 3 ? '修改' : '请选择' }}</a-button>
            </a-form-item>

            <a-form-item label="年卡" name="username">
              <span style="margin-right: 20px;color:red" v-if="selectDiscountMode.couponType == 2"> {{ '已选择' }}</span>
              <a-button class="ui-coupon__btn" type="primary" @click="onGetCardTap('card', 2)">{{ selectDiscountMode.couponType == 2 ? '修改' : '请选择' }}</a-button>
            </a-form-item>

            <a-form-item label="兑换券" name="username">
              <span style="margin-right: 20px;color:red" v-if="selectDiscountMode.couponType == 4"> {{ selectDiscountMode.showData.title }}</span>
              <a-button class="ui-coupon__btn" type="primary" @click="onGetCardTap('coupon')">{{ selectDiscountMode.couponType == 4 ? '修改' : '请选择' }}</a-button>
            </a-form-item>

            <a-form-item label="原价" name="username" v-show="selectDiscountMode.couponType != 6 && selectDiscountMode.couponType != 8">
              <span>{{orderDetail.totalPrice}}</span>
            </a-form-item>

<!--            <a-form-item label="优惠" name="username" v-show="selectDiscountMode.couponType != 6 && selectDiscountMode.couponType != 8">
              <span>优惠</span>
            </a-form-item>-->

            <a-form-item label="需补差" name="username" style="color: red;font-size: 24px;font-weight: bold;">
              <span>{{orderDetail.payPrice}}</span>
            </a-form-item>

            <a-form-item label="会员抵扣价" name="payPrice" v-show="selectDiscountMode.couponType == 6 || selectDiscountMode.couponType == 8">
              <span>{{orderDetail.deductionAmount}}</span>
            </a-form-item>

          </a-form>
        </div>
        <!-- 支付优惠 end -->

        <!-- 第三方线下 start -->
        <div v-if="formState.payType == 9" class="ui-pay__box">
          <a-form ref="offlineForm" :model="offlineFormState">
            <a-form-item label="单座位价格" name="price">
              <span>{{ (orderDetail.totalPrice / orderDetail.num).toFixed(2) }}</span>
            </a-form-item>

            <a-form-item label="原价" name="originPrice">
              <span>{{orderDetail.totalPrice}}</span>
            </a-form-item>

            <a-form-item label="单座位实际支付金额" name="offlinePrice"  :rules="[{required: true, message: '实际支付金额不能为空'}]">
              <a-input prefix="¥" style="width: 200px" v-model:value="offlineFormState.offlinePrice"></a-input>
            </a-form-item>

            <a-form-item label="总价" name="payPrice">
              {{ (offlineFormState.offlinePrice * orderDetail.num).toFixed(2) }}
            </a-form-item>

            <a-form-item label="第三方平台" name="payPrice">
              <a-radio-group  v-model:value="tripartitePlatform">
                <a-radio :value="1">抖音</a-radio>
              </a-radio-group>
            </a-form-item>

          </a-form>
        </div>
        <!-- 第三方线下 end -->

        <!-- 现金支付 start -->
        <div v-if="formState.payType == 10" class="ui-pay__box">
          <a-form ref="offlineForm" :model="offlineFormState">
            <a-form-item label="单座位价格" name="price">
              <span>{{orderDetail.totalPrice / orderDetail.num}}</span>
            </a-form-item>

            <a-form-item label="原价" name="originPrice">
              <span>{{orderDetail.totalPrice}}</span>
            </a-form-item>

            <a-form-item label="单座位实际支付金额" name="offlinePrice"  :rules="[{required: true, message: '实际支付金额不能为空'}]">
              <a-input prefix="¥" style="width: 200px" v-model:value="offlineFormState.offlinePrice"></a-input>
            </a-form-item>

            <a-form-item label="总价" name="payPrice">
              {{ offlineFormState.offlinePrice * orderDetail.num }}
            </a-form-item>

          </a-form>
        </div>
        <!-- 现金支付 end -->

        <a-row justify="center">
          <a-button @click="onPreBtnTap" style="margin-right:100px">上一步</a-button>
          <a-button type="primary" html-type="submit" @click="onConfirmOrderTap">确认</a-button>
        </a-row>

      </a-form>

      <a-modal :visible="showCardModal" :title="cardModalTitle" width="700" @ok="onCardSelectOk" @cancel="onCardSelectCancel">
        <a-spin :spinning="loading">
          <a-table
            :columns="cardColumns"
            :data-source="cardList"
            :row-selection="cardState"
            :pagination="false"
            :scroll="{x: 500}"
            rowKey="id"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'createTime'">
                {{ transDateTime(record.createTime) }}
              </template>
              <template v-if="column.key === 'times'">
                {{ record.usableTotal + '/' + record.cardBalance }}
              </template>
              <template v-if="column.key === 'status'">
                {{ record.status }}
              </template>
              <template v-if="column.key === 'cardStatus'">
                <span v-if="record.status == 1">正常</span>
                <span v-if="record.status == 2">过期</span>
                <span v-if="record.status == 3">停用</span>
              </template>
              <template v-if="column.key === 'expireDate'">
                <span v-if="record.isNeverExpire">
                  永不过期
                </span>
                <span v-else>
                  {{ transDateTime(record.expireDate , 1) }}
                </span>
              </template>
              <template v-if="column.key === 'isDisabled'">
                {{ record.isDisabled ? '已禁用' : '已启用' }}
              </template>
            </template>
          </a-table>
        </a-spin>
      </a-modal>


      <a-modal :visible="showCouponModal" title="兑换券" width="700" @ok="onCouponSelectOk" @cancel="onCouponSelectCancel">
        <a-table
          :columns="couponColumns"
          :data-source="couponList"
          :row-selection="couponState"
          :pagination="false"
          :scroll="{x: 500}"
          rowKey="id"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'startTime'">
              {{ transDateTime(record.startTime) }}
            </template>
            <template v-if="column.key === 'endTime'">
              {{ transDateTime(record.endTime) }}
            </template>
            <template v-if="column.key === 'status'">
              {{ record.status }}
            </template>
            <template v-if="column.key === 'isDisabled'">
              {{ record.isDisabled ? '已禁用' : '已启用' }}
            </template>
          </template>
        </a-table>
      </a-modal>

      <!-- 二次确认弹窗-->
      <a-modal :visible="showConfirmModalFlag" title="购票提示" @ok="onOrderTap" @cancel="onCancelTap">
        <a-spin :spinning="loading">
          <p>影院: {{ seatData.cinemaData.cinemaName }}</p>
          <p>购买《{{ orderDetail.filmName }}》{{ transDateTime(orderDetail.showDateTime) }}</p>
          <p>
            <span>{{ orderDetail.hallName }}</span>
            <span v-for="(item, index) in orderDetail.preTicketSeatVOS">
              {{item.rowId}}排{{item.columnId}}列
              <span v-if="index != (orderDetail.preTicketSeatVOS.length - 1)">、</span>
            </span>
          </p>
  <!--        <p>支付方式：{{transPayType()}}</p>-->
          <p>支付方式：{{showPayType}}</p>
          <p v-if="formState.payType == 9 || formState.payType == 10">实际支付：¥{{ offlineFormState.offlinePrice * orderDetail.num }}元</p>
          <p v-else-if="formState.payType == 1 && selectDiscountMode.couponType != 6 && selectDiscountMode.couponType != 8">需补差：¥{{ orderDetail.payPrice }}元</p>
          <p v-else>抵扣金额：¥{{ orderDetail.deductionAmount }}元</p>
        </a-spin>
      </a-modal>

    </div>
  </a-spin>


</template>

<script>
  import {
    getMyInfo,
    getCardLowestPrice,
    getCardList,
    getCouponList,
    preOrderTicket,
    orderTicket,
    printTicket,
    releaseSeat,
    collectTickets
  } from "@/service/modules/buyTickets";
import printJS from 'print-js'
export default {
  props: {
    seatData: {
      type: Object,
      default () {
        return {}
      }
    },
    userPhone: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data() {
    return {
      formState:{
        payType: -1, // 1. 9.第三方线下 10.现金支付
      },
      confirmPhoneFlag: false, // 手机确定情况, 需要修改手机号需要改为false
      visible: false,
      loading:false,
      columns: [
        {
          title: '影片',
          key:'filmName',
          dataIndex: 'filmName'
        },{
          title: '时间',
          key:'showDateTime',
          dataIndex: 'showDateTime'
        },{
          title: '影院',
          key:'cinemaName',
          dataIndex: 'cinemaName'
        },{
          title: '座位',
          key:'preTicketSeatVOS',
          dataIndex: 'ticketData'
        }

        ],
      list: [
      ],
      modelType:0,//弹窗类型 0确认弹窗 1选择弹窗
      myInfo: {},//用户信息
      vipCardPrice: {},
      page: {
        page: 1,
        hasNextPage: false
      },
      cardType: 0,
      couponId: [],	// 已选择优惠卡ID
      cardBgUrl:'',
      preTicketSeatVOS: [],
      selectCouponId: [],	// 正在选择中优惠卡ID
      amount: 0,
      orderDetail:{},
      offlinePrice:'',//第三方线下每张实际
      cashPrice:'',//现金支付
      phone: "",
      timeout: null,
      // cardColumns:[
      //   {
      //     title: 'Name',
      //     dataIndex: 'name',
      //     scopedSlots: { customRender: 'name' },
      //   }
      // ],
      showCouponModal: 0, // 券弹窗, 展示优惠弹窗, 依据当前的券类型
      showCardModal: 0, // card 弹窗, // 1.年卡 2次卡 3.影城会员卡(实体卡) 4.线上
      cardList: [],
      cardColumns: [],
      couponColumns:[
        {
          title: '影院名称',
          dataIndex: 'cinemaName'
        }, {
          title: '批次号',
          dataIndex: 'batchNo'
        }, {
          title: '兑换券号',
          dataIndex: 'barcode'
        }, {
          title: '有效期开始时间',
          key: "startTime",
          width: 200
        }, {
          title: '有效期结束时间',
          key: "endTime",
          width: 200
        }, {
          title: '券状态',
          width: 100,
          dataIndex: 'status',
          key: 'status'
        }, {
          title: '状态',
          width: 100,
          dataIndex: 'isDisabled',
          key: 'isDisabled'
        },
      ],
      couponState: {
        selectedRowKeys: [],
        selectRecord: [],
        type: 'checkbox',
        hideSelectAll: true,
        onSelect: (record, selected)=>{
          this.onSelectCoupon(record, selected);
        }
      },
      cardState: {
        selectedRowKeys: [],
        selectRecord: [],
        type: 'radio',
        onSelect: (record, selected)=>{
          this.onSelectCard(record, selected);
        }
      },
      selectDiscountMode: {
        mode: '', // card: 卡, coupon: 券
        couponType: 0, // 1.兑换券 2.年卡 3.次卡 4.优惠券 5.小卖券 6.充值卡(线上会员卡) 7.抖音券 8.影城会员卡
        cardId: 0,
        couponIds: [],
        showData: {
          title: '', //展示券名称/卡的等级
        },
      },
      couponList: [],
      tripartitePlatform: 1, // 1: 抖音, 目前默认使用抖音
      offlineFormState: {
        offlinePrice: '', // 第三方线下支付
      },
      showConfirmModalFlag: false,
      orderId: 0, //生成订单后, 赋值
      cardModalTitle: '', // 展示标题
    }
  },
  created() {
    if (this.userPhone){
      this.phone = this.userPhone
    }
    this.list.push(this.seatData)
    this.orderDetail = this.seatData?.ticketData
    this.setCountdown();
  },
  methods: {
    async getMyInfo() {
      let res = await getMyInfo({
        cinemaId: this.seatData?.cinemaData?.cinemaId,
        storeId: this.seatData?.cinemaData?.storeId,
        source:3,
        phone: this.phone
      });
      if (res.code == 200) {
        this.myInfo = res.data.myInfo;
        this.getCardLowestPrice();
      }
      this.loading = false;
    },
    async getCardLowestPrice(){
      let ret = await getCardLowestPrice({
        showId: this.seatData?.id,
        seatCodes: this.seatData?.ticketData.preTicketSeatVOS.map(item => item.seatCode).join(','),
        phone: this.phone,
        cinemaId: this.seatData?.cinemaData.cinemaId,
      });
      if (ret.code === 200) {
        this.vipCardPrice = ret.data;
      }
    },
    onGetCardTap(mode, type) {
      // mode: 优惠方式, card:会员卡/次卡/年卡
      // type: 当mode=='card', // 1.年卡 2次卡 3.影城会员卡(实体卡) 4.线上
      if (mode == 'card') {
        console.log(mode, type,"mode, type")
        if (type == 2) {
          this.cardModalTitle = '年卡';
          this.cardColumns = [
            {
              title: '影院名称',
              dataIndex: 'cinemaName'
            }, {
              title: '卡等级',
              dataIndex: 'cardTitle'
            }, {
              title: '卡号',
              dataIndex: 'cardNumber'
            }, {
              title: '有效期',
              key: 'expireDate',
              width: 120
            }, {
              title: '开卡时间',
              key: "createTime",
              width: 200
            }, {
              title: '卡状态',
              width: 100,
              key: 'cardStatus'
            }, {
              title: '状态',
              width: 100,
              dataIndex: 'isDisabled',
              key: 'isDisabled'
            },
          ];
        } else if (type == 1) {
          this.cardModalTitle = '次卡';
          this.cardColumns = [
            {
              title: '影院名称',
              dataIndex: 'cinemaName'
            }, {
              title: '卡等级',
              dataIndex: 'cardTitle'
            }, {
              title: '卡号',
              dataIndex: 'cardNumber'
            }, {
              title: '是否可用',
              key: 'times',
              width: 120
            }, {
              title: '已用/剩余次数',
              key: 'times',
              width: 120
            }, {
              title: '有效期',
              key: 'expireDate',
              width: 120
            }, {
              title: '卡状态',
              width: 100,
              key: 'cardStatus'
            }, {
              title: '状态',
              width: 100,
              dataIndex: 'isDisabled',
              key: 'isDisabled'
            },
          ];
        } else {
          this.cardModalTitle = '会员卡';
          this.cardColumns = [
            {
              title: '影院名称',
              dataIndex: 'cinemaName'
            }, {
              title: '卡等级',
              dataIndex: 'cardTitle'
            }, {
              title: '卡号',
              dataIndex: 'cardNumber'
            }, {
              title: '卡余额',
              dataIndex: 'balance',
              width: 120
            }, {
              title: '单座位抵扣价',
              dataIndex: 'deductionAmount',
            }, {
              title: '绑定时间',
              key: "createTime",
              width: 200
            }, {
              title: '卡状态',
              width: 100,
              dataIndex: 'status',
              key: 'status'
            }, {
              title: '状态',
              width: 100,
              dataIndex: 'isDisabled',
              key: 'isDisabled'
            },
          ];
        }
        this.getCardList(type);
      } else if (mode == 'coupon') { // type: 当mode=='coupon',//优惠券
        this.getCouponList();
      }
    },
    // 会员卡/次卡/年卡列表
    async getCardList(type) {
      const postData ={
        page: 1,
        pageSize: 999,
        type,	// 1.次卡 2.年卡 3.影城充值卡 4.影城会员卡
        userId: this.myInfo.id,
        status: 0,
        showId: this.seatData?.id,
        cinemaId:this.seatData?.cinemaData.cinemaId,
        phone: this.phone
      };
      if (type == 1) {
        postData.status = 1;
      }
      let ret = await getCardList(postData);
      if (ret.code === 200) {
        this.cardList = ret.data.list;
        this.showCardModal = type;
      }
    },
    onSelectCard(record, selected) {
      if (selected) {
        this.cardState.selectedRowKeys = [record.id];
        this.cardState.selectRecord = [record];
      } else {
        this.cardState.selectedRowKeys = [];
      }
    },
    // 兑换券选择
    onSelectCoupon(record, selected) {
      if (selected) {
        this.couponState.selectedRowKeys.push(record.id);
        this.couponState.selectRecord.push(record);
      } else {
        const index = this.couponState.selectedRowKeys.findIndex(item=>item == record.id);
        this.couponState.selectedRowKeys.splice(index, 1);
        this.couponState.selectRecord.splice(index, 1);
      }
    },
    // 卡变化方法
    onCardSelectOk() {
      // 1.年卡 2次卡 3.影城会员卡(实体卡) 4.线上
      if (this.showCardModal == 1) {
        // 年卡
        this.selectDiscountMode.couponType = 3; // 次卡
        this.selectDiscountMode.cardId = this.cardState.selectedRowKeys[0];
      } else if (this.showCardModal == 2) {
        // 次卡
        this.selectDiscountMode.couponType = 2; // 年卡
        this.selectDiscountMode.cardId = this.cardState.selectedRowKeys[0];
      } else if (this.showCardModal == 3) {
        this.selectDiscountMode.couponType = 6; // 线上会员卡
        this.selectDiscountMode.cardId = this.cardState.selectedRowKeys[0];
        this.selectDiscountMode.showData.title = this.cardState.selectRecord[0].cardNumber; // 这里先展示卡号吧
      } else if (this.showCardModal == 4) {
        this.selectDiscountMode.couponType = 8; // 影城会员卡
        this.selectDiscountMode.cardId = this.cardState.selectedRowKeys[0];
        this.selectDiscountMode.showData.title = this.cardState.selectRecord[0].cardNumber; // 这里先展示卡号吧
      }
      this.prePay(); // 获取价格
      console.log(this.selectDiscountMode.couponType,"this.selectDiscountMode.couponTypethis.selectDiscountMode.couponType")
    },
    // 券变化
    onCouponSelectOk() {
      // 判断券与座位数量
      const couponNum = this.couponState.selectedRowKeys.length;
      const seatNum = this.orderDetail.preTicketSeatVOS.length;
      if (!couponNum) {
        // 没有选择券的处理
        this.$message.error('请选择兑换券');
        return;
      } else if (couponNum > seatNum) {
        this.$message.error('最多选择' + seatNum + '张兑换券。 一个座位最多选择一张兑换，请重新选择兑换券!')
        return;
      }
      console.log(this.couponState.selectedRowKeys)
      this.selectDiscountMode.couponType = 4; // 兑换券
      this.selectDiscountMode.couponIds = this.couponState.selectedRowKeys.join(',');
      this.selectDiscountMode.showData.title = `已选择${couponNum}张兑换券`; // 券号号吧
      this.prePay(); // 获取价格
      this.showCouponModal = 0;
    },
    onCardSelectCancel() {
      this.cardState.selectedRowKeys = [];
      this.showCardModal = 0;
    },
    onCouponSelectCancel() {
      this.couponState.selectedRowKeys = [];
      this.showCouponModal = 0;
    },
    // 兑换券
    async getCouponList() {
      // if (!this.page.hasNextPage) return;
      let ret = await getCouponList({
        page: this.page.page,
        pageSize: 20,
        couponType: 1,	// 1:系统优惠券 2:兑换券
        userId: this.myInfo.id,
        status: 1,
        cinemaId:this.seatData?.cinemaData.cinemaId,
        phone:this.phone
      });
      if (ret.code === 200) {
        console.log(ret.data)
        this.couponList = ret.data.list
        this.showCouponModal = 1
      }
    },
    onClearCouponTap() {
      // 清空优惠政策
      this.selectDiscountMode.cardId = 0;
      this.selectDiscountMode.couponIds = 0;
      this.selectDiscountMode.couponType = 0;
      this.selectDiscountMode.showData.title = '';
      // 清空coupon选择器
      this.couponState.selectedRowKeys = [];
      this.couponState.selectRecord = [];
      // 清空card选择器
      this.cardState.selectedRowKeys = [];
      this.cardState.selectRecord = [];
      this.prePay();
    },
    async prePay() {
      this.loading = true;
      try {
        const postData = {
          cinemaId: this.seatData?.cinemaData?.cinemaId,
          storeId: this.seatData?.cinemaData?.storeId,
          source: 3,
          showId: this.seatData?.id,
          seatCodes: this.seatData?.ticketData.preTicketSeatVOS.map(item => item.seatCode).join(','),
          phone: this.phone,
        };
        // 判断是否用支付优惠
        let couponType = '';
        if(this.cardType == 10 || this.cardType == 9){
          couponType = this.cardType
        } else if (this.cardType == 1 && this.selectDiscountMode.couponType == 6) {
          // 线上会员卡
          postData.couponType = this.selectDiscountMode.couponType;
          postData.cardId = this.selectDiscountMode.cardId;
          console.log(postData.cardId)
        } else if (this.cardType == 1 && this.selectDiscountMode.couponType == 8) {
          // 影城会员卡
          console.log('影城会员卡')
          postData.couponType = this.selectDiscountMode.couponType;
          postData.cardId = this.selectDiscountMode.cardId;
        } else if (this.cardType == 1 && this.selectDiscountMode.couponType == 3) {
          // 次卡
          console.log('次卡')
          postData.couponType = this.selectDiscountMode.couponType;
          postData.cardId = this.selectDiscountMode.cardId;
        } else if (this.cardType == 1 && this.selectDiscountMode.couponType == 2) {
          // 年卡
          console.log('年卡')
          postData.couponType = this.selectDiscountMode.couponType;
          postData.cardId = this.selectDiscountMode.cardId;
        } else if (this.cardType == 1 && this.selectDiscountMode.couponType == 4) {
          //兑换券
          console.log('兑换券')
          postData.payType = this.selectDiscountMode.couponType;
          postData.couponIds = this.selectDiscountMode.couponIds;
        }
        if (couponType) postData.couponType = couponType;
        const ret = await preOrderTicket(postData);

        if (ret.code === 200) {
          this.showCardModal = 0;
          if (this.cardType === 6 || this.cardType === 8) {
            this.amount = ret.data.deductionAmount
          } else {
            // this.amount = 0;
          }
          this.orderDetail = ret.data;
          this.preTicketSeatVOS = ret.data.preTicketSeatVOS;

        } else  if (ret.code == 605026) {
          // 还有卡券不能使用
          this.selectDiscountMode.cardId = 0;
          this.selectDiscountMode.cardId = 0;
          this.selectDiscountMode.couponType = 0;
          this.selectDiscountMode.showData.title = '';
        } else if (ret.code == 605000) {
          // 座位释放了
          this.releaseSeat();
        } else {
          // 错误处理
        }
        this.loading = false;
      } catch(e) {
        this.loading = false;
      }

    },
    //倒计时
    transCountDownTime(val) {
      // console.log(val)
      let minutes = this.moment.duration(Number(val), 'second').minutes();
      let seconds = this.moment.duration(Number(val), 'seconds').seconds();
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return minutes + ':' + seconds;
    },
    onPreBtnTap(){
      // 释放桌位
      releaseSeat({
        showId: this.seatData?.id,
        seatCodes: this.seatData?.ticketData.preTicketSeatVOS.map(item => item.seatCode).join(','),
        storeId: 9,
        cinemaId: 9,
      }).then(res => {
        this.$emit("changeCurrent", { index:2,seatData:this.seatData,phone:this.phone})
      }).catch(err => {
        this.$emit("changeCurrent", { index:2,seatData:this.seatData,phone:this.phone})
      })
    },
    async onConfirmOrderTap(){
      if (this.formState.payType == -1) {
        this.$message.error('请选择支付方式');
        return;
      }
      if (this.formState.payType == 9 || this.formState.payType == 10) {
        try {
          const res = await this.$refs.offlineForm.validate();
          this.showConfirmModalFlag = true; // 弹窗确认弹窗
        } catch (e) {
          console.log(e)
        }
      } else if (this.formState.payType == 1 && this.selectDiscountMode.couponType == 0) {
        this.$confirm({
          title: '提示',
          content: '未选择卡券，是否直接使用现金支付？',
          onOk: () => {
            this.showConfirmModalFlag = true;
          }
        })
      }  else if (this.formState.payType == 1) {
        this.showConfirmModalFlag = true; // 弹窗确认弹窗
      }
    },
    onOrderTap() {
      // 确认下单,
      this.submitOrder();
    },
    onCancelTap() {
      // 取消下单, 关闭弹窗
      this.showConfirmModalFlag = false;
    },
    async submitOrder() {
      // 提交订单, 打印电影票
      const postData = {
        showId: this.seatData?.id,
        seatCodes: this.seatData?.ticketData.preTicketSeatVOS.map(item => item.seatCode).join(','),
        source: 3,
        cinemaId: this.seatData?.cinemaData?.cinemaId,
        storeId: this.seatData?.cinemaData?.storeId,
      };
      if (this.phone) { // 不传手机号后台默认账号
        postData.phone = this.phone;
      }
      // payType类型 1.兑换券 2.年卡 3.次卡 4.优惠券 5.卖品券 6.线上会员卡 7.抖音券 8.影城会员卡 9.第三方线下 10.现金支付
      if (this.formState.payType == 1) {
        if (!this.selectDiscountMode.couponType) {
          postData.couponType = 10;
        } else {
          postData.couponType = this.selectDiscountMode.couponType;
          if (this.selectDiscountMode.couponType == 2 || this.selectDiscountMode.couponType == 3 || this.selectDiscountMode.couponType == 6 || this.selectDiscountMode.couponType == 8) {
            postData.cardId = this.selectDiscountMode.cardId;
          } else if (this.selectDiscountMode.couponType == 4) {
            postData.couponIds = this.selectDiscountMode.couponIds;
          }
        }
      }
      if (this.formState.payType == 9) {
        postData.pcPayment = this.offlineFormState.offlinePrice;
        postData.tripartitePlatform = this.tripartitePlatform; // 默认抖音
        postData.couponType = this.formState.payType;
      }
      if (this.formState.payType == 10) {
        postData.couponType = this.formState.payType;
        postData.pcPayment = this.offlineFormState.offlinePrice;
      }
      try {
        this.loading = true;
        const ret = await orderTicket(postData);
        if (ret.code == 200) {
          // 获得pdf 打印影票
          this.orderId = ret.data.id;
          const printRet = await printTicket({orderId: ret.data.id});
          if (printRet.code == 200) {
            this.printUrl = printRet.data;
            // 调用打印方法
            this.onPrintTicket(this.printUrl);
          } else {
            // 生成文件错误, 联系管理员
            this.loading = false;
            this.$confirm({
              title: '提示',
              content: '出票异常，请联系管理员',
              okText: '好的',
              closable: false,
              cancelButtonProps: {
                disabled: true
              },
              onOk: () => {
                // 刷新页面, 回到选电影院页
                this.$emit("changeCurrent",{
                  index: 1,
                  cinemaData: this.seatData?.cinemaData,
                  phone: ''
                })
              }
            })
          }
          this.showConfirmModalFlag = false;
        } else if (ret.code == 75000){
          this.loading = false;
          this.releaseSeat();
        } else {
          this.loading = false;
        }
      } catch (e) {
        console.log(e)
        this.loading = false;
      }
    },
    showPrintTips() {
      this.$confirm({
        title: '提示',
        content: '请在打印完成后再关闭提示框',
        okText: '打印完成',
        cancelText: '补打影票',
        closable: false,
        onOk: () => {
          // 刷新页面, 回到选影院页
          this.$emit("changeCurrent", {
            index: 1,
            cinemaData: this.seatData?.cinemaData,
            phone: ''
          })
          this.$destroyAll();
        },
        onCancel: () => {
          // 重新打印
          this.onPrintTicket(this.printUrl);
          return true;
        }
      })
    },
    releaseSeat(msg){
      // 释放座位或者是错误
      this.$confirm({
        title: '错误',
        content: msg || '座位已被释放，请重新选座下单',
        okText: '返回选座页',
        cancelText: '返回选择影片页',
        closable: false,
        onOk: () => {
          // 返回选座页, 选座页需要入参影片与影院数据
          this.$emit("changeCurrent", {
            index: 2,
            seatData: this.seatData,
            phone: this.phone
          })
        },
        onCancel: () => {
          //  返回选择影片页, 选择影片页需入参影院数据
          this.$emit("changeCurrent",{
            index: 1,
            cinemaData: this.seatData?.cinemaData,
            phone: ''
          })
        }
      })
    },
    onPrintTicket(pdf) {
      printJS({
        type: 'pdf',
        printable: pdf,
        onPdfOpen: true, //打印pdf时，如果浏览器不兼容（请检查浏览器兼容性表），库将在新选项卡中打开pdf。
        onPrintDialogClose: () => {
          // 关闭打印弹窗回调
        },
        onLoadingStart: () => {
          // 开始加载pdf完成回调
        },
        onLoadingEnd: () => {
          // 加载pdf完成回调
          this.showPrintTips();
          this.submitPrintStatus();
          this.loading = false;
        },
        onError: (err)=>{
          this.showPrintTips();
          this.loading = false;
          console.log('打印影票错误=>>>>>', err);
        }
      })
    },
    async submitPrintStatus() {
      // 打印影票后调用出票接口
      if (this.hadPirnt) return;
      const ret = await collectTickets({
        orderId: this.orderId
      });
      if (ret.code == 200) {
        this.hadPirnt = true;
      }
    },
    onConfirmPhoneTap() {
      if (!this.phone) return;
      this.loading = true;
      this.getMyInfo();
      this.confirmPhoneFlag = true;
    },
    onEditPhoneTap() {
      // 1.清楚已选配置 2.支付方式清空
      this.confirmPhoneFlag = false;
      this.formState.payType = -1;
      this.onClearCouponTap();
    },
    setCountdown() {
      if (this.orderDetail.lockSeatTime > 0) {
        this.orderDetail.lockSeatTime = Number(this.orderDetail.lockSeatTime) - 1;
        this.timeout = setTimeout(() => {
          this.setCountdown();
        }, 1000);
      } else {
        this.modelType = 2
        this.visible = true
      }
    },
    // 修改支付方式
    onChange(e){
      if (e.target.value == 1){
        this.cardType = 1
      }
      if (e.target.value == 9){
        this.cardType = 9
      }
      if (e.target.value == 10){
        this.cardType = 10
      }
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
    showPayType() {
      if (this.formState.payType == 1 && this.selectDiscountMode.couponType == 0) {
        return '现金支付';
      }
      if (this.formState.payType == 9) {
        return '第三方线下';
      } else if (this.formState.payType == 10) {
        return '现金支付';
      }
      return {
        1: '兑换券',
        2: '年卡',
        3: '次卡',
        4: '兑换券',
        5: '',
        6: '线上会员卡',
        7: '',
        8: '影城会员卡',
        9: '第三方线下',
        10: '现金支付',
      }[this.selectDiscountMode.couponType];
    },
  },
  beforeUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }
};
</script>
<style scoped>
.ui-main {
  padding-bottom: 50px;
}
.ui-pay__box{
  margin: 0 auto 30px;
  /*margin-bottom: 30px;*/
  width: 385px;
}

.ui-pay__box.is-coupon {
    width: 400px;
}

.ui-pay__box div{
  display: flex;
  justify-content: start;
  align-items: center
}
.cardItem{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  //border: 1px gray solid;
}
.cardRadio{
  display: flex;
  align-items: center;
}
.cardType{
  margin-top: 10px;
}

.ui-coupon__btn {
    align-items: flex-end;
}

</style>

