export default function transDate(value, type) {
  if ((value + '').length < 13) value *= 1000;
  var d = new Date(value);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var day = d.getDate();
  var timeStr = d.toTimeString();
  var time = timeStr.substring(0, timeStr.indexOf('GMT'));
  time = time.substring(0, time.length);
  var date = d.getDay();
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  var array = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
  var strDate = year + '-' + month + '-' + day + ' ' + time;
  if (type == 1) {
    return year + '.' + month + '.' + day;
  } else if (type == 2) {
    return year + '年' + month + '月' + day + '日';
  } else if (type == 3) {
    // 12:20
    return time.substring(0, time.length - 4);
  } else if (type == 4) {
    return month + '-' + day;
  }
  return strDate;
}
