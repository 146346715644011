export default function movieClass(classifyInfo){
  // 提取每个对象的 name 属性
  if (classifyInfo) {
    let names = JSON.parse(classifyInfo || '').map(function(item) {
      return item.name;
    });
    // 用空格连接所有的 name
    let result = names.join(' ');
    return result
  } else {
    return ''
  }
}