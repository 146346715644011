<template>
  <div class="ui-main">
    <a-form ref="form" :model="formState" name="form" @finish="onNextTap" layout="horizontal">
<!--      <a-form-item class="ui-form__item" label="用户手机号" name="phone"  style="width: 280px;position: relative;left: 285px;">
        <a-input
          placeholder="请输入用户手机号"
          v-model:value="formState.phone"
        ></a-input>
      </a-form-item>-->
      <a-form-item class="ui-form__item" label="影院组织" name="organizationId" :rules="[{ required: true, message:'必选项不允许为空'}]">
        <a-select placeholder="请选择" v-model:value="formState.organizationId"
                  @change="getAllCinemaList" style="width: 280px;">
          <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
            {{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="ui-form__item" name="cinemaId" label="所属影院"  :rules="[{ required: true, message:'必选项不允许为空'}]">
        <a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 280px;" @change="handleChange">
          <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-button type="primary" html-type="submit">下一步</a-button>
    </a-form>
  </div>

</template>

<script>
import { getCinemaList, getOrganizationList } from "@/service/modules/cinema";
export default {
  props: {
    cinemaData: {
      type: Object,
      default () {
        return {}
      }
    },
    userPhone: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data() {
    return {
      organizationList: [],
      cinemaAllList: [],
      formState: {
        organizationId: '',
        phone:'',
        cinemaName:"",
        cinemaId:''
      },
    }
  },
  created() {
    if (this.cinemaData){
        this.formState.organizationId = this.cinemaData?.organizationId
        this.formState.cinemaId = this.cinemaData?.cinemaId

    }
    if (this.userPhone){
      this.formState.phone = this.userPhone
    }
    this.getOrganizationList();
  },
  methods: {
    onNextTap() {
      const cinemaData = this.formState;
      console.log(JSON.stringify(cinemaData), 'cinemaData')
      delete cinemaData.phone;
      this.$emit("changeCurrent",{
        index: 1,
        cinemaData,
        phone: this.formState.phone
      });
      this.$setLS('buyTicketCinema', cinemaData);
    },
    async getOrganizationList() {
      let ret = await getOrganizationList({
        page: 1,
        pageSize: 999999
      });
      if (ret.code === 200) {
        this.organizationList = ret.data.list;
        if (this.organizationList.length) {
          this.formState.organizationId = this.organizationList[0].id;
          this.getAllCinemaList(this.formState.organizationId);
        }
      }
    },
    async getAllCinemaList(organizationId) {
      // this.cinemaAllList = [];
      // this.formState.cinemaId = 0;
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
        organizationId: organizationId ? organizationId : undefined
      })
      if (ret.code === 200) {
        this.cinemaAllList = ret.data.list;
      }
    },

    handleChange(value){
      this.cinemaAllList.forEach((item)=>{
        if(item.id == value){
          this.formState.cinemaName = item.name
          this.formState.storeId = item.storeId
        }
      })
    }
  },
};
</script>

<style scoped>

.ui-main {
    display: flex;
    justify-content: center;
    width: 900px;
    margin: 0 auto;
}
</style>
